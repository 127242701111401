import React from 'react';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { TKUITheme } from '..';
import { useTheme } from 'react-jss';
import Util from '../util/Util';


type TKGoogleLayerProps = Partial<React.ComponentProps<typeof ReactLeafletGoogleLayer>> & { googleMapsLoaderConf: { KEY: string } }

const TKGoogleLayer: React.FunctionComponent<TKGoogleLayerProps> =
    props => {
        const theme = useTheme<TKUITheme>();
        const { attribution, ...restProps } = props;
        return (
            <ReactLeafletGoogleLayer
                styles={theme.isDark ? darkStyles : undefined}
                key={theme.isDark ? "dark" : "light"}   // Do this to re-create the component on appearance change to force refresh.
                {...restProps}
                attribution={Util.addSkedGoTermsToMapAttribution(attribution)}
            />
        )
    }

const darkStyles = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
    },
]

export default TKGoogleLayer;