import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import TripGoApi from "tripkit-react/dist/api/TripGoApi";
import RegionsData from "tripkit-react/dist/data/RegionsData";
import GeolocationData from "tripkit-react/dist/geocode/GeolocationData";
import LatLng from "tripkit-react/dist/model/LatLng";
import NetworkUtil from "tripkit-react/dist/util/NetworkUtil";
import UIUtil from "tripkit-react/dist/util/UIUtil";
import booleanPointInPolygon from 'tripkit-react/node_modules/@turf/boolean-point-in-polygon';
import { point, multiPolygon } from "tripkit-react/node_modules/@turf/helpers";
import { i18n } from "tripkit-react/dist/i18n/TKI18nConstants";

interface IClientContext {
    clients?: Client[];
    clientsPRef?: MutableRefObject<Promise<Client[]> | undefined>;
    client?: Client;
    onSelectClient: (client: Client) => void;
}

export const ClientContext = React.createContext<IClientContext>({
    onSelectClient: () => { }
});

let { apiKey } = appConfig;
const isSuperApp = apiKey === "e735bbf789be4c1f6947e62d45fbfa81";   // Catch-a-ride

const CLIENT_STORAGE_KEY = "CLIENT";
export function getStoredClient(): Client | undefined {
    try {
        return isSuperApp && localStorage.getItem("CLIENT") ? JSON.parse(localStorage.getItem("CLIENT")!) : undefined;
    } catch (e) {
        return undefined;
    }
}
const DISMISS_ANOTHER_REGION_WARNING = "DISMISS_ANOTHER_REGION_WARNING";

export function mockUserLocationIfDev(userLocation: LatLng): LatLng | Promise<LatLng> {
    let enabled: boolean = false;
    // enabled = true;
    if (enabled && process.env.NODE_ENV === 'development') {
        return LatLng.createLatLng(44.352270978136346, -89.08049583435059) // Test Waupaca
        // return LatLng.createLatLng(39.52205163048525,-119.82354640960695) // Test Reno - N4
        // return LatLng.createLatLng(46.769086607307266, -92.12040424346925) // Test Duluth - Arrowhead
        // return Promise.resolve(LatLng.createLatLng(46.769086607307266, -92.12040424346925))
        // .then(TKNetworkUtil.delayPromise(2000)) // Test Duluth - Arrowhead
    } else {
        return userLocation;
    }
}

const ClientProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [clients, setClients] = useState<Client[] | undefined>(undefined);
    const [client, setClient] = useState<Client | undefined>(undefined);

    function onSelectClient(client: Client, options: { resetRegions?: boolean, resetUserToken?: boolean } = {}) {
        const { resetRegions = true, resetUserToken = true } = options;
        localStorage.setItem(CLIENT_STORAGE_KEY, JSON.stringify(client));
        TripGoApi.clientID = client.clientID;
        if (resetRegions) {
            RegionsData.reset();
            RegionsData.regionsJsonPromise = undefined;
        }
        if (resetUserToken) {
            TripGoApi.resetUserToken();
        }
        setClient(client);
    }

    const clientsPromise = useRef<Promise<Client[]>>();

    useEffect(() => {
        if (isSuperApp) {
            // Store the whole client object to load client app instantaneously for the returning client, avoiding
            // to wait for the clients.json request. When it arrives, I need to replace the selected client with the
            // corresponding one coming from clients.json, so it's consistent, and also in case anything changed.
            const storedClient = getStoredClient();
            if (storedClient) {
                // Avoid resetting the user token since if so it will be requested twice with the same clientId 
                // (the first request will already be with the proper clientID from LS)
                // Still need to reset regions since TKRegionsData.regionsJsonPromise was set with fallback to avoid failure (TripPlannerApp).
                onSelectClient(storedClient, { resetUserToken: false });
            }
            // Tried initializing the useRef with the TripGoApi.apiCall but it doesn't work well: it first resolves to empty
            // client's list. So I need to pass the ref to the context, instead of just the promise.
            clientsPromise.current = TripGoApi.apiCall("clients.json", NetworkUtil.MethodType.GET)
                .then(clients => {
                    setClients(clients);
                    if (storedClient) {
                        const inClients = clients.find(clientI => clientI.clientID === storedClient.clientID);
                        if (inClients) {
                            onSelectClient(inClients, { resetRegions: false, resetUserToken: false });
                        } else {
                            // This may happen if the stored client is not a valid client anymore according to clients.json list, 
                            // so remove old client and reload.
                            localStorage.removeItem(CLIENT_STORAGE_KEY);
                            window.location.reload();
                        }

                    }
                    return clients;
                });
            if (storedClient) {
                GeolocationData.instance.requestCurrentLocation(true, false)
                    .then(userPosition => userPosition.latLng)
                    .then(mockUserLocationIfDev)
                    .then((userLatLng: LatLng) => {
                        clientsPromise.current!.
                            then((clients) => {
                                const storedClient = getStoredClient();
                                if (storedClient && !booleanPointInPolygon(point([userLatLng.lng, userLatLng.lat]), multiPolygon(storedClient.polygon.coordinates))) {
                                    const matching = clients!.filter(client => {
                                        return booleanPointInPolygon(point([userLatLng.lng, userLatLng.lat]), multiPolygon(client.polygon.coordinates));
                                    });
                                    if (matching.length === 1 && localStorage.getItem(DISMISS_ANOTHER_REGION_WARNING) !== matching[0].clientID) {
                                        onSelectClient(matching[0]);
                                        UIUtil.confirmMsg({
                                            title: matching[0].clientName,
                                            message: i18n.t("You.are.currently.in.the.coverage.area.of.this.provider..Would.you.like.to.use.your.app.here?"),
                                            confirmLabel: i18n.t("Use.App.Here"),
                                            onConfirm: () => {
                                                // onSelectClient(matching[0]) 
                                            },
                                            cancelLabel: i18n.t("Dismiss"),
                                            onCancel: () => {
                                                onSelectClient(storedClient);
                                                localStorage.setItem(DISMISS_ANOTHER_REGION_WARNING, matching[0].clientID);
                                            }
                                        });
                                    }
                                }
                            });
                    })
                    .catch(() => { });
            }
        }
    }, []);

    const clientContext: IClientContext = {
        clients,
        clientsPRef: clientsPromise,
        client,
        onSelectClient
    };
    return (
        <ClientContext.Provider value={clientContext}>
            {children}
        </ClientContext.Provider>
    );
};

export default ClientProvider;