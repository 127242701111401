import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "reflect-metadata";
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import TripPlannerApp from "./app/TripPlannerApp";


function renderTripPlanner(containerId: string = "root", apiKey: string) {
    const containerElement = document.getElementById(containerId) as HTMLElement;
    ReactDOM.render(
        <TripPlannerApp />, containerElement
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const global: any = window as any;
global.renderTripPlanner = renderTripPlanner;

if (process.env.NODE_ENV === 'development') {
    // require("./mock/mock.js");
}

renderTripPlanner("tripgo-planner", "032de02a53a155f901e6953bcdbf77ad");
