import React, { useContext, useState } from 'react';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { TKUITheme, black, white } from "tripkit-react/dist/jss/TKUITheme";
import { ReactComponent as IconCity } from "tripkit-react/dist/images/location/ic-city.svg.js";
import { ReactComponent as IconCurrentLoc } from "tripkit-react/dist/images/location/ic-curr-loc.svg.js";
import { ReactComponent as IconRightArrow } from "tripkit-react/dist/images/ic-angle-right.svg.js";
import GeolocationData from 'tripkit-react/dist/geocode/GeolocationData';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { resetStyles } from 'tripkit-react/dist/css/ResetStyle.css';
import { TKUserPosition } from 'tripkit-react/dist/util/GeolocationUtil';
import booleanPointInPolygon from 'tripkit-react/node_modules/@turf/boolean-point-in-polygon';
import { point, multiPolygon } from "tripkit-react/node_modules/@turf/helpers";
import { TKUICard, TKUIResponsiveUtil } from 'tripkit-react';
import { TKUICardClientProps } from 'tripkit-react/dist/card/TKUICard';
import classNames from 'classnames';
import { ClientContext, mockUserLocationIfDev } from './ClientProvider';

const clientSelectorStyle = (theme: TKUITheme) => ({
    selector: {
        ...genStyles.flex,
        ...genStyles.column,
        ...genStyles.alignCenter,
        padding: '50px 0',
        ...genStyles.borderRadius(12),
        boxSizing: 'border-box',
        width: '100%',
        background: white(0, theme.isDark),
        '& *': {
            fontFamily: theme.fontFamily
        },
        ['@media (max-width: ' + TKUIResponsiveUtil.getPortraitWidth() + 'px)']: {
            '& $title, $message': {
                maxWidth: '90%'
            },
            '& $options': {
                maxWidth: '90%'
            }
        }
    },
    logo: {
        with: '150px',
        height: '150px'
    },
    title: {
        ...theme.textWeightBold,
        fontSize: '24px',
        marginTop: '30px'
    },
    options: {
        ...genStyles.flex,
        ...genStyles.column,
        marginTop: '30px',
        width: '380px'
    },
    option: {
        ...resetStyles.button,
        ...genStyles.borderRadius(10),
        background: black(4, theme.isDark),
        ...genStyles.flex,
        ...genStyles.alignCenter,
        padding: '10px 15px',
        marginBottom: '10px',
        ...theme.textSizeBody,
        ...theme.textWeightSemibold,
        // '&:disabled': {
        //     opacity: '.5'
        // }
    },
    selectedOption: {
        border: '2px solid ' + theme.colorPrimary
    },
    fadedOption: {
        opacity: '.5'
    },
    optionName: {
        ...genStyles.grow,
        textAlign: 'start',
        margin: '0 10px'
    },
    icCity: {
        '& path': {
            fill: black(1, theme.isDark)
        }
    },
    iconRightArrow: {
        '& path': {
            fill: black(1, theme.isDark)
        }
    },
    icCurrentLoc: {
        color: theme.colorPrimary,
        '& path': {
            fill: 'currentColor'
        }
    },
    resolvingCurrLoc: {
        animation: 'colorchange 2s infinite',
        WebkitAnimation: 'colorchange 2s infinite'
    },
    message: {
        margin: '20px',
        textAlign: 'center',
        color: theme.colorPrimary
    }
})

type IStyle = ReturnType<typeof clientSelectorStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    renderCaption?: () => React.ReactNode;
    logoUrl?: string;
    cardProps?: TKUICardClientProps;
    requestCurrentLocSilently?: boolean;
    onRequestClose?: (selection?: boolean) => void;
}

const SelectorOption: React.FunctionComponent<{ renderIcon: () => React.ReactNode, label: string, onClick: () => void, classes: any, disabled?: boolean, selected?: boolean }> =
    ({ renderIcon, label, onClick, classes, disabled, selected }) => {
        return (
            <button className={classNames(classes.option, selected && classes.selectedOption, disabled && classes.fadedOption)} onClick={onClick}>
                {renderIcon()}
                <div className={classes.optionName}>
                    {label}
                </div>
                <IconRightArrow className={classes.iconRightArrow} />
            </button>
        );
    }

const ClientSelector: React.FunctionComponent<IProps> = ({ renderCaption, logoUrl, cardProps, onRequestClose, classes, t }) => {

    const { clients, clientsPRef, client: selectedClient, onSelectClient: onSelect } = useContext(ClientContext);

    const [matchingClients, setMatchingClients] = useState<Client[] | undefined>(undefined);
    const [geolocating, setGeolocating] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    function onCurrentLocClick() {
        setGeolocating(true);
        GeolocationData.instance.requestCurrentLocation(false, true)
            .then((userPosition: TKUserPosition) => userPosition.latLng)
            .then(mockUserLocationIfDev)
            .then(userLatLng => {
                setGeolocating(false);
                if (userLatLng) {
                    // Need to wait until clients arrive
                    clientsPRef?.current?.then(clients => {                        
                        const matching = clients!.filter(client => {
                            return booleanPointInPolygon(point([userLatLng.lng, userLatLng.lat]), multiPolygon(client.polygon.coordinates));
                        })
                        if (matching.length === 1) {
                            onSelect(matching[0]);
                            onRequestClose?.(true);
                        } else if (matching.length > 1) {
                            setMessage(t("Multiple.matching.regions.found.for.your.current.location..Please.select.one.from.the.list."));
                            setMatchingClients(matching);
                        } else {
                            setMessage(t("No.matching.region.found.for.your.current.location..Please.select.one.from.the.list."));
                        }
                    });
                }
            });
    }

    // useEffect(() => {
    //     if (clients && requestCurrentLocSilently) {
    //         setGeolocating(true);
    //         GeolocationData.instance.requestCurrentLocation(true, false)
    //             .then((userPosition: TKUserPosition) => userPosition.latLng)
    //             .then(mockUserLocationIfDev)
    //             .then(userLatLng => {
    //                 setGeolocating(false);
    //                 console.log("userLatLng:");
    //                 console.log(userLatLng);
    //                 if (userLatLng) {
    //                     // Need to wait until clients arrive to call this.
    //                     const matching = clients!.filter(client => {
    //                         return booleanPointInPolygon(point([userLatLng.lng, userLatLng.lat]), multiPolygon(client.polygon.coordinates));
    //                     })
    //                     console.log("matching:");
    //                     console.log(matching);
    //                     if (matching.length === 1) {
    //                         setMatchingClients(matching);
    //                     } else if (matching.length > 1) {
    //                         // UIUtil.errorMsg(new TKError("Your location is covered by more than one region, plase manually select the desired one."));
    //                         setMessage("Your location is covered by more than one region, plase select one from the list.")
    //                         setMatchingClients(matching);
    //                     } else {
    //                         // UIUtil.errorMsg(new TKError("Your location is not covered by any available region, plase manually select the desired one."));
    //                         setMessage("Your location is not covered by any available region, plase select one from the list.");
    //                     }
    //                     // throw "User location outside bounds."
    //                 }
    //             });
    //     }
    //     console.log("Client selector creation")
    // }, [clients]);

    return (

        <TKUICard
            {...cardProps}
        >
            <div className={classes.selector}>
                {logoUrl &&
                    <img src={logoUrl} className={classes.logo} />}
                {renderCaption ? renderCaption() :
                    <div className={classes.title}>
                        {t("Where.do.you.want.to.use.this.app?")}
                    </div>}
                <div className={classes.options}>
                    {<SelectorOption
                        renderIcon={() => <IconCurrentLoc className={classNames(classes.icCurrentLoc, geolocating && classes.resolvingCurrLoc)} />}
                        label={t("Current.Location")}
                        onClick={onCurrentLocClick}
                        classes={classes}
                    />}
                    {clients?.map(client =>
                        <SelectorOption
                            renderIcon={() => <IconCity className={classes.icCity} />}
                            label={client.clientName}
                            onClick={() => {
                                onSelect(client);
                                onRequestClose?.(true);
                            }}
                            classes={classes}
                            key={client.clientID}
                            disabled={matchingClients && !matchingClients.includes(client)}
                            selected={client === selectedClient}
                        />)}
                </div>
                <div className={classes.message}>
                    {message}
                </div>
            </div>
        </TKUICard>
    );
}

export default withStyles(ClientSelector, clientSelectorStyle);