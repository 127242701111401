import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import {
    TKUITripPlanner,
    TKRoot,
    TKUIConfig,
    LatLng,
    TKPeliasGeocoder, TKUIMapViewProps,
    TKStateUrl,
    TKGeocodingOptions,
    TKUtil,
    genClassNames,
    black,
    TKRegionsData,
    TKRegionResults,
    TKAccountProvider,
    TKUIAccountBtn,
    TKUISignInFromSettings,
    TKUIUserAccountSetting,
    TKUITheme,
    TKUISettingSection,
    TKUISettingLink,
    TripGoApi,
    TKUserProfile,
    CardPresentation,
    withStyles,
    genStyles,
    TKUIViewportUtil,
    TKUISlideUpPosition,
} from 'tripkit-react';
import classNames from "classnames";
import { ReactComponent as LogoTestflight } from "../images/test-flight.svg";
import { ReactComponent as LogoGooglePlay } from "../images/google-play.svg";
import appConfig from "../config/appConfig";
import TKUIStripePaymentCard from 'tripkit-react/dist/stripekit/TKUIStripePaymentCard';
import OptionsProvider, { IOptionsContext, OptionsContext } from 'tripkit-react/dist/options/OptionsProvider';
import TGUIDevSettingsView, { getApiKey, getServer, setPredefinedApiKeys, setPredefinedServers } from '../../../../options/TGUIDevSettingsView';
import { TripSort } from 'tripkit-react/dist/model/trip/TripSort';
import TKUIMxMBookingCard from 'tripkit-react/dist/mxm/TKUIMxMBookingCard';
import { IThemeCreatorProps } from 'tripkit-react/dist/config/TKUIConfig';
import ClientSelector from '../client/ClientSelector';
import Color from 'tripkit-react/dist/model/trip/Color';
import bbox from "tripkit-react/node_modules/@turf/bbox";
import BBox from 'tripkit-react/dist/model/BBox';
import { TKUIWithClasses, overrideClass } from 'tripkit-react/dist/jss/StyleHelper';
import ClientProvider, { ClientContext, getStoredClient } from '../client/ClientProvider';
import { useTKState } from 'tripkit-react/dist/config/TKStateProvider';
import TKUIConfigProvider, { TKUIConfigContext } from 'tripkit-react/dist/config/TKUIConfigProvider';
import { cardSpacing } from 'tripkit-react/dist/jss/TKUITheme';
import TKMapboxGLLayer from 'tripkit-react/dist/map/TKMapboxGLLayer';
import TKGoogleLayer from 'tripkit-react/dist/map/TKGoogleLayer';
import TKGoogleGeocoder from 'tripkit-react/dist/geocode/TKGoogleGeocoder';
import { TKTripCostType } from 'tripkit-react/dist/trip/TKUITripRow';
import { default as TKFormatUtil } from "tripkit-react/dist/util/FormatUtil";

let { apiKey, auth0Domain, auth0ClientId, regionsFallback, name, logoHasName, environment, imagesUrl, theme, stripeKey, justEnabledByDefault } = appConfig;

const isSuperApp = apiKey === "e735bbf789be4c1f6947e62d45fbfa81";   // Catch-a-ride

const isBeta = environment === Envs.BETA;
const isStaging = environment === Envs.STAGING;
const isProd = !isBeta && !isStaging;

if (regionsFallback) {
    TKRegionsData.regionsJsonFallback = require('../config/' + regionsFallback);
    // To avoid requesting regions.json without clientID at init, which happens the very first time the user has never selected a client app 
    // (no client object in LS).
    if (isSuperApp) {
        TKRegionsData.regionsJsonPromise = Promise.resolve(TKRegionsData.regionsJsonFallback);
    }
}

setPredefinedApiKeys({
    'production': apiKey
});
if (isBeta) {   // TGUIDevSettingsView uses the first entry as the default, so this is to make SATAPP_BETA the default server when isBeta.
    setPredefinedServers({
        'beta': TripGoApi.SATAPP_BETA,
        'production': TripGoApi.SATAPP
    });
} else if (isStaging) {
    setPredefinedServers({
        'staging': "https://api-feonix.tripgo.com/v1"
    });
}
const devSettings = isBeta;

// Workaround to currency coming from BE with value "USD" instead of "$".
const originalTKToMoney = TKFormatUtil.toMoney;
TKFormatUtil.toMoney = (n, options) => originalTKToMoney(n, { ...options, currency: "$" });

const WithTKRootConfig: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {

    const locale = navigator.language === ('es') || navigator.language.startsWith("es-") ? 'es' : 'en-US';

    const geocodeEarth = new TKPeliasGeocoder({
        server: "https://api.geocode.earth/v1",
        apiKey: "ge-63f76914953caba8",
        restrictToBounds: true,
        resultsLimit: 5
    });

    const googleGeocoder = new TKGoogleGeocoder({
        restrictToBounds: true,
        resultsLimit: 5,
        lang: 'en-US'
    });

    const { client } = useContext(ClientContext);
    let name = isSuperApp ? client?.clientName ?? "" : (appConfig.name + (isBeta ? " beta" : ""));
    let logoHasName = isSuperApp ? false : appConfig.logoHasName;

    const defaultRegions: TKRegionResults = TKUtil.deserialize(TKRegionsData.regionsJsonFallback, TKRegionResults);
    const userLocationPromise = useMemo(() => {
        const tKUserLocationPromise = (window as any).tKUserLocationPromise;
        if (!isSuperApp && tKUserLocationPromise) {
            /**
            * Start with map on (initViewport) default city (the first one of hardcoded region) until wait for user location.
            * When user location resolves, if it lays inside region bounds, then focus map on it, otherwise do nothing.
            */
            const defaultRegions: TKRegionResults = TKUtil.deserialize(TKRegionsData.regionsJsonFallback, TKRegionResults);
            return tKUserLocationPromise
                .then((userCoords: [number, number]) => LatLng.createLatLng(userCoords[0], userCoords[1])) // .then(TKNetworkUtil.delayPromise(4000))
                .then((userLatLng: LatLng) => {
                    for (const region of defaultRegions.regions) {
                        if (region.contains(userLatLng)) {
                            return userLatLng;
                        }
                    }
                    throw "User location outside bounds."
                })
        } else {
            return Promise.reject("Don't locate user");
        }
    }, []);


    const defaultRegion = defaultRegions.regions[0];
    const defaultCityLatLng = defaultRegion.cities[0];

    // TODO: make this to use client's region(s) instead of (super-app's) default one, will require setting / updating it each time the client changes.
    let defaultUserProfile;
    if (justEnabledByDefault) {
        defaultUserProfile = new TKUserProfile();
        defaultRegion?.modes.forEach(mode => // for each mode
            !justEnabledByDefault!.find(enabled => mode === enabled) && // that is not in justEnabledByDefault
            defaultUserProfile.transportOptions.setTransportOption(mode, 2)); // disable it.
    }

    const config: TKUIConfig = {
        apiKey: "", // Set below, on TKRoot
        // apiServer: satappServer, // Set below, on TKRoot        
        theme: (themeProps) => ({
            fontFamily: 'ProximaNova, sans-serif',
            ...TKUtil.isFunction(theme) ? (theme as ((props: IThemeCreatorProps) => TKUITheme))(themeProps) : theme,
            ...client && {
                colorPrimary: Color.fromJSON(client.appColors.tintColor).toHex()
            }
        }),
        initViewport: !isSuperApp ? { center: defaultCityLatLng, zoom: 13 } : undefined,
        fixToInitViewportRegion: !isSuperApp,
        i18n: {
            locale: locale,
            translations: require(`../i18n/i18n_${locale}.json`)
        },
        geocoding: (defaultOptions: TKGeocodingOptions) => {
            const geocoders = {
                ...defaultOptions.geocoders,
                ...false ?
                    { geocodeEarth } :
                    { google: googleGeocoder }
            }
            delete geocoders['recent'];     // Remove recent since duplicate detection, based on distance, don't work with google results (prediction), which come withoug lat lng.
            return ({
                geocoders,
                reverseGeocoderId: false ? undefined : 'google',
                restrictToCoverageBounds: true,
                getFocus: (({ mapViewport }) => {
                    return mapViewport?.center;
                })
            });
        },
        booking: {
            renderBookingCard: props => <TKUIMxMBookingCard {...props} />,
            enabled: isBeta && name === "N4 Connect" ? undefined : segment => !segment.isPT() // Enable TT for N4. TODO: add a WL config flag.
        },
        payment: stripeKey ? {
            renderPaymentCard: props => <TKUIStripePaymentCard {...props} />,
            stripePublicKey: stripeKey
        } : undefined,
        defaultUserProfile,
        TKUIMapView: {
            props: (props: TKUIMapViewProps) => ({
                renderLayer: false ? () =>
                    <TKMapboxGLLayer
                        accessToken={"pk.eyJ1IjoibWdvbWV6bHVjZXJvIiwiYSI6ImNqa3N3aTQ0cjAxZ3UzdnRnbWtyZDY4bXMifQ.mLGxFRgw2xvCmNa8DVrtxA"}
                        style={props.theme.isLight ?
                            "mapbox://styles/mgomezlucero/ckjliu0460xxh1aqgzzb2bb34" :
                            "mapbox://styles/mgomezlucero/ckbmm6m0w003e1hmy0ksjxflm"}
                        attribution={"<a href='http://osm.org/copyright' tabindex='-1'>OpenStreetMap</a>"}
                    /> : () =>
                    <TKGoogleLayer
                        googleMapsLoaderConf={{
                            KEY: window.location.hostname.includes("localhost") ? "AIzaSyCZckxpqD6Cqez5VNbcmzCIBUSGgUNUyao" : "AIzaSyBPGqg-SOLk3vKht3i3gl4ydLkkbsVPF34",
                            LANGUAGE: locale,
                            LIBRARIES: ['places']
                        }}
                    />
            })
        },
        TKUITripPlanner: {
            props: (props) => ({
                userLocationPromise,
                renderTopRight: props.landscape ?
                    () => (
                        <div className={classNames(genClassNames.flex, genClassNames.alignCenter)}>
                            <div style={{ marginRight: '20px' }} className={classNames(genClassNames.flex, genClassNames.alignCenter)}>
                                <div style={{ height: "60px" }}>
                                    <img src={imagesUrl + (props.theme.isDark ? "/logo-map-dark.png" : "/logo-map.png")} style={{ height: '100%' }} />
                                </div>
                                {!logoHasName &&
                                    <div style={{
                                        fontFamily: props.theme.fontFamily,
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        color: black(0, props.theme.isDark),
                                        marginLeft: '7px'
                                    }}>
                                        {name}
                                    </div>}
                            </div>
                            <TKUIAccountBtn />
                        </div>
                    )
                    : undefined
            })
        },
        TKUISidebar: {
            styles: {
                header: overrideClass({
                    height: 'initial'
                })
            },
            props: (props) => ({
                renderLogo: () => (
                    <div style={{ height: '100%', padding: '16px 10px 16px 27px', ...!logoHasName ? { marginRight: '20px' } : undefined }} className={classNames(genClassNames.flex, genClassNames.column, genClassNames.alignStart)}>
                        <div style={{ height: "75px" }}>
                            <img src={imagesUrl + (props.theme.isDark ? "/logo-sidebar-dark.png" : "/logo-sidebar.png")} style={{ height: '100%' }} />
                        </div>
                        {!logoHasName &&
                            <div style={{
                                fontFamily: props.theme.fontFamily,
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: black(0, props.theme.isDark),
                                margin: '5px 0 0 16px'
                            }}>
                                {name}
                            </div>}
                    </div>
                ),
                appStoreUrl: !isBeta ? 'https://apps.apple.com/app/n4-connect/id1586953992' : undefined,
                playStoreUrl: !isBeta ? 'https://play.google.com/store/apps/details?id=com.skedgo.client.feonixn4connect' : undefined,
                nativeAppsTitle: isBeta ? "Test beta mobile app:" : undefined,
                renderNativeAppLinks: isBeta ? () => (
                    <React.Fragment>
                        <div onClick={() => window.open("https://testflight.apple.com", '_blank')}
                            className={classNames(genClassNames.flex, genClassNames.alignCenter)}
                            style={{
                                height: '70px',
                                width: '144px',
                                cursor: 'pointer'
                            }}
                        >
                            <LogoTestflight style={{ height: '100%', marginRight: '15px' }} />
                            iOS app on TestFlight
                        </div>
                        <div onClick={() => window.open("https://play.google.com/store/apps", '_blank')}
                            className={classNames(genClassNames.flex, genClassNames.alignCenter)}
                            style={{
                                height: '70px',
                                width: '170px',
                                cursor: 'pointer'
                            }}
                        >
                            <LogoGooglePlay style={{ height: '100%', marginRight: '15px' }} />
                            Android app on Google Play
                        </div>
                    </React.Fragment>
                ) : undefined
            })
        },
        TKUIPrivacyOptionsView: {
            props: {
                privacyPolicyUrl: appConfig.termsUrl ?? "https://n4connect.tripgo.com/n4ConnectPrivacy"
            }
        },
        TKUIRoutingResultsView: {
            props: {
                tripMetricsToShow: [TKTripCostType.price],
                tripSortingsToShow: [TripSort.OVERALL],
                tripBadgesToShow: []
            }
        }
    };

    return (
        <OptionsProvider>
            <OptionsContext.Consumer>
                {(optionsContext: IOptionsContext) =>
                    <TKRoot
                        config={{ ...config, apiKey: getApiKey(optionsContext.userProfile), apiServer: getServer(optionsContext.userProfile) }}
                    >
                        {children}
                    </TKRoot>
                }
            </OptionsContext.Consumer>
        </OptionsProvider>
    );
};

const tripPlannerAppStyle = (theme: TKUITheme) => ({
    clientSelectorContainer: {
        ...genStyles.flex,
        ...genStyles.center,
        width: '100%',
        height: '100%',
        background: black(3, theme.isDark),
        '& > *': {
            width: '100%',
            margin: '30px'
        }
    }
});

type IStyle = ReturnType<typeof tripPlannerAppStyle>
interface IProps extends TKUIWithClasses<IStyle, IProps> {
    portrait?: boolean;
}

/**
 * Needed to break down the component in two parts to be able to add styles (using withStyles), 
 * which requires to be a descendent of TKRoot. An issue is that config should be defined on parent component,
 * since need to be passed to TKRoot, while UI is defined in child component, which requires passing some parameters.
 */
const TripPlannerAppUI: React.FunctionComponent<IProps> = (props: IProps) => {
    const { classes, t, portrait } = props;
    const landscape = !portrait;
    const config = useContext(TKUIConfigContext);
    const { client } = useContext(ClientContext);
    const { mapAsync, setShowUserProfile } = useTKState()
    const [showDevSettings, setShowDevSettings] = useState<boolean>(false);
    const [showClientSelector, setShowClientSelector] = useState<boolean>(isSuperApp && !getStoredClient());
    const isClientSelectorFromSettings = client !== undefined;

    useEffect(() => {
        if (client) {
            const turfbbox = bbox(client.polygon);
            const [minX, minY, maxX, maxY] = turfbbox;
            const boundingBox = BBox.createBBox(LatLng.createLatLng(maxY, maxX), LatLng.createLatLng(minY, minX));
            mapAsync.then(map => {
                map.fitBounds(boundingBox);
            });
        }
    }, [client?.clientID]); // To fit map if client actually changed

    const devSettingsView = showDevSettings &&
        <TGUIDevSettingsView
            onRequestClose={() => setShowDevSettings(false)}
        />;

    let clientSelector =
        <ClientSelector
            logoUrl={isClientSelectorFromSettings ? undefined : imagesUrl + "/logo-circular.svg"}
            renderCaption={isClientSelectorFromSettings ?
                // This is not translated.
                // () => "Please select where you want to use this app."
                () => t("Where.do.you.want.to.use.this.app?")
                : undefined}
            cardProps={{
                presentation: isClientSelectorFromSettings ? (landscape ? CardPresentation.MODAL : CardPresentation.SLIDE_UP) : CardPresentation.NONE,
                title: isClientSelectorFromSettings ? t("Select.Region") : undefined,
                onRequestClose: isClientSelectorFromSettings ? () => setShowClientSelector(false) : undefined,
                slideUpOptions: {
                    initPosition: TKUISlideUpPosition.UP,
                    modalUp: { top: cardSpacing(landscape), unit: 'px' },
                    draggable: false
                },
                focusTrap: true
            }}
            onRequestClose={() => {
                // This callback is triggered on selection (unlike the one in the card)
                setShowClientSelector(false);
                setShowUserProfile(false);
            }}
        />;
    if (!isClientSelectorFromSettings) {
        clientSelector =
            <div className={classes.clientSelectorContainer}>
                {clientSelector}
            </div>
    }


    const configOverride = {
        ...config,
        TKUIProfileView: {
            props: {
                accountSettings: () =>
                    <TKUIUserAccountSetting
                        phoneNote={<>To update your phone number, please call <a href="tel:1-833-653-6544">1-833-653-6544</a> and request technical support. A live agent will be able to assist you. The ability to reach you regarding your trip plans is of utmost importance and we appreciate your support with this matter.</>}
                    />,
                customSettings: () =>
                    <Fragment>
                        <TKUISettingSection>
                            <TKUISettingLink
                                text={t("Select.Region")}
                                onClick={() => setShowClientSelector(true)}
                            />
                        </TKUISettingSection>
                        <TKUISignInFromSettings />
                        {devSettings &&
                            <TKUISettingSection>
                                <TKUISettingLink text={"Beta Testing"} onClick={() => setShowDevSettings(true)} />
                            </TKUISettingSection>}
                    </Fragment>
            }
        }
    }

    return (
        <TKUIConfigProvider config={configOverride}>
            {showClientSelector && !isClientSelectorFromSettings ?
                clientSelector
                :
                <TKAccountProvider
                    auth0Domain={auth0Domain}
                    auth0ClientId={auth0ClientId}
                    exclusiveModes={appConfig.exclusiveModes}>
                    <TKUITripPlanner />
                    <TKStateUrl />
                    {showClientSelector && isClientSelectorFromSettings && clientSelector}
                </TKAccountProvider>}
            {devSettingsView}
        </TKUIConfigProvider>
    );
}

const TripPlannerAppUIStyled = withStyles(TripPlannerAppUI, tripPlannerAppStyle)

/**
 * Modularizing this way allows the UI part, TripPlannerAppUIStyled, to be under TKRoot,
 * so allowing to use all SDK features (e.g. JSS styles, SDK state contexts, etc.) 
 */
export default () =>
    <ClientProvider>
        <WithTKRootConfig>
            <TKUIViewportUtil>
                {({ portrait }) =>
                    <TripPlannerAppUIStyled portrait={portrait} />}
            </TKUIViewportUtil>
        </WithTKRootConfig>
    </ClientProvider>