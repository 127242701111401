import { TKUIStyles } from "tripkit-react/dist/jss/StyleHelper";
import { TGUIDevSettingsViewProps, TGUIDevSettingsViewStyle } from "./TGUIDevSettingsView";
import { tKUIProfileViewDefaultStyle } from "tripkit-react/dist/options/TKUIProfileView.css";
import { resetStyles } from "tripkit-react/dist/css/ResetStyle.css";
import { colorWithOpacity, TKUITheme } from "tripkit-react/dist/jss/TKUITheme";
import genStyles from "tripkit-react/dist/css/GenStyle.css";

export const tGUIDevSettingsViewDefaultStyle: TKUIStyles<TGUIDevSettingsViewStyle, TGUIDevSettingsViewProps> =
    (theme: TKUITheme) => {
        const profileViewStyle = (tKUIProfileViewDefaultStyle as any)(theme);
        return ({
            ...profileViewStyle,
            apiKeyOption: {
                ...genStyles.flex,
                ...genStyles.alignCenter,
                ...genStyles.spaceBetween,
                ...theme.textSizeCaption,
                ...theme.textWeightSemibold,
                ...theme.textColorGray,
                cursor: 'pointer',
                padding: '8px 12px',
                '&:active': {
                    backgroundColor: colorWithOpacity(theme.colorPrimary, .4)
                }
            },
            apiKeyOptionFocused: {
                backgroundColor: colorWithOpacity(theme.colorPrimary, .2)
            },
            apiKeyOptionSelected: {
                color: 'white',
                backgroundColor: colorWithOpacity(theme.colorPrimary, .5)
            },
            apiKeyEditBtn: {
                ...resetStyles.button,
                '&:hover': {
                    textDecoration: 'underline'
                }
            },
            optionSelect: {
                ...profileViewStyle.optionSelect,
                minWidth: '200px'
            },
            shortcut: {
                ...theme.textColorGray,
                ...theme.textSizeBody,
                fontStyle: 'italic'
            }
        });
    };