import { IThemeCreatorProps } from "tripkit-react/dist/config/TKUIConfig";
import { TKUITheme } from "tripkit-react/dist/jss/TKUITheme";
export interface AppConfig {
    satappServer: string;
    apiKey: string;
    auth0Domain: string;
    auth0ClientId: string;
    regionsFallback: string;
    name: string;
    logoHasName?: boolean;
    imagesUrl: string;
    theme: Partial<TKUITheme> | ((props: IThemeCreatorProps) => Partial<TKUITheme>);
    environment: keyof typeof Envs;
    stripeKey?: string;
    justEnabledByDefault?: string[];
    termsUrl?: string;
    exclusiveModes?: boolean;
}

export default (require('appConfig') as AppConfig);